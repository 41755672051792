* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

html {
    scroll-behavior: smooth; }

// Custom slider indicators
.carousel-indicators {
    li {
        display: none; } }

.carousel-control-prev {
    span {
        display: none; } }
.carousel-control-next {
    span {
        display: none; } }

/* Row reset*/
.row {
    margin: 0;
    padding: 0; }

@keyframes show {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

.animated {
    opacity: 0;
    animation: show 1s ease forwards; }

@keyframes animateOutAnim {
    0% {
        opacity: 1;
        display: block; }
    99% {
        opacity: 0; }
    100% {
        display: none;
        opacity: 0;
        z-index: -1; } }

.animateOut {
    animation: animateOutAnim 1s ease forwards; }

.preload-fix {
    min-height: 100vh; }

.carousel-inner {
    height: 100%; }

.carousel-item {
    height: 100%; }

.height-70vh {
    height: 70vh;
    @media screen and ( max-height: 1000px ) {
        height: 60vh; } }

.sliderSmallContainer {
    @media screen and (max-width: 600px) {
        width: 80% !important; }

    @media screen and (max-height: 400px) {
        width: 80% !important; } }
